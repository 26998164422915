import store from './src/state';
export const wrapRootElement = store;

// scrolls to top on page change
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  const currentPosition = getSavedScrollPosition(location);
  if (location.pathname.startsWith('/', '/actualites', '/adhesion', '/conseil-administratif', '/contact', '/cotations', '/formations', '/histoire-du-sail', '/installation', '/juridique', '/legal', '/recherche', '/404')) {
    return window.scrollTo(...(currentPosition || [0, 0])), 600;
  }
};

