import { all, call, put, takeLatest } from 'redux-saga/effects';
import { navigate } from 'gatsby-link';
import * as contentful from "contentful";

import {
  getSearchSuccess,
  getSearchFailure,
  sendContactFormFailure,
  sendContactFormSuccess,
  openPopup,
  getContentTypeEntriesSuccess,
  getContentTypeEntriesFailure
} from './reducer';
import { SEARCH_LIMIT } from '../utils/constants';


// CONFIG
const client = contentful.createClient({
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
  // environment: "master"
});

// SEARCH
function* doGetSearchResults(action) {
  const { value, skip } = action.payload;
  try {
    /**
     * @see https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters/full-text-search
     */
    const res = yield client.getEntries({
      query: value,
      limit: SEARCH_LIMIT,
      skip
    })
    if (res.items) {
      //const contentTypesToFilter = ['sponsors', 'seo', 'Legal', 'pub'] // To filter more content types, add the value of item.sys.contentType.sys.id
      const contentTypesToKeep = ['articles', 'installation', 'formation', 'document'];
      const filteredItems = res.items.filter(item => contentTypesToKeep.includes(item.sys.contentType.sys.id));

      yield put(getSearchSuccess({ items: filteredItems, res }));
    }
    navigate('/recherche')
  } catch (error) {
    console.log('🚀 ~ file: sagas.js ~ line 23 ~ function*doGetSearchResults ~ error', error)
    yield put(getSearchFailure())
    yield put(openPopup({ open: true, message: "Impossible de récupérer les résultats de recherche, merci de réessayer dans quelques instants", error: true, popupId: "search-error" }))
  }
}

const postContact = async (payload) => {
  const stringifyData = JSON.stringify(payload);

  const response = await fetch(`${process.env.GATSBY_APIGATEWAY_URL}/messages`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': process.env.GATSBY_API_KEY,
    },
    body: stringifyData
  });

  return response.json();
}

// SEND MESSAGE
function* doSendContactForm({ payload }) {
  try {
    yield call(postContact, payload);
    yield put(sendContactFormSuccess());
    yield put(openPopup({ open: true, message: "Votre demande nous a bien été transmise. \nNous vous recontacterons le plus rapidement possible.", error: false, popupId: "contact" }))
  } catch (error) {
    console.log('🚀 ~ file: sagas.js ~ line 58 ~ function*doGetSearchResults ~ error', error)
    yield put(sendContactFormFailure())
    yield put(openPopup({ open: true, message: "Nous rencontrons momentanément un problème,\nveuillez réessayer ultérieurement", error: true, popupId: "contact" }))
  }
}

// GET CONTENT TYPE ENTRIES
/**
 * @see https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters/order/query-entries/console/js
 */
function* doGetContentTypeEntries(action) {
  const { id, date, limit } = action.payload;
  try {
    let params = {
      content_type: id,
    }
    if (date) {
      params = {
        ...params,
        'fields.date[gte]': date,
        order: 'fields.date'
      }
    }
    if (limit) {
      params = {
        ...params,
        limit,
      }
    }
    const results = yield client.getEntries(params)
    yield put(getContentTypeEntriesSuccess(results.items))
  } catch (error) {
    yield put(getContentTypeEntriesFailure())
    yield put(openPopup({ open: true, message: "Nous rencontrons momentanément un problème,\nveuillez réessayer ultérieurement", error: true, popupId: "get content type entries" }))
  }
}

export default function* LeadCreationSaga() {
  yield all([
    takeLatest('root/getSearchLaunched', doGetSearchResults),
    takeLatest('root/sendContactFormLaunched', doSendContactForm),
    takeLatest('root/getContentTypeEntriesLaunched', doGetContentTypeEntries),
  ]);
}
