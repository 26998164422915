import Immutable from 'immutable';
import { createSlice } from "@reduxjs/toolkit";
import { SEARCH_SKIP } from '../utils/constants';

const initialState = Immutable.Map({
  searchSubCategory :null,
  searchValue: null,
  isLoading: false,
  searchResults: [],
  searchErrorMessage: null,
  searchSkip: SEARCH_SKIP,
  contactStateMessage: null,
  shouldResetForm: null,
  errorModale: false,
  popupOpen: false,
  popupMessage: null,
  popupError: false,
  popupId: null,
  contentTypeEntries: []
});

const { actions, reducer } = createSlice({
  slice: 'root',
  name: 'root',
  initialState,
  reducers: {
    // Search results
    getSearchLaunched: (state, action) => state
      .set('searchValue', action.payload)
      .set('isLoading', true),
    searchSubCategory:(state, action) => state
      .set('searchSubCategory', action.payload),
    getSearchSuccess: (state, action) => state
      .set('isLoading', false)
      .set('searchResults', action.payload),
    getSearchFailure: (state, action) => state
      .set('isLoading', false)
      .set('searchErrorMessage', action.payload),

    // Search results pagination
    setSearchSkip: (state, action) => state
      .set('searchSkip', action.payload),

    // Contact
    sendContactFormLaunched: (state, action) => state
      .set('isLoading', true),
    sendContactFormSuccess: (state, action) => state
      .set('isLoading', false)
      .set('shouldResetForm', true),
    sendContactFormFailure: (state, action) => state
      .set('isLoading', false)
      .set('shouldResetForm', false),

    // Popup
    openPopup: (state, action) => state
      .set('popupOpen', action.payload.open)
      .set('popupMessage', action.payload.message)
      .set('popupError', action.payload.error)
      .set('popupId', action.payload.popupId),

    // ResetPopup
    resetPopupStore: (state, action) => state
      .set('popupOpen', false)
      .set('popupMessage', null)
      .set('popupError', false)
      .set('popupId', null)
      .set('shouldResetForm', null),

    // Get A ContentType's entries
    getContentTypeEntriesLaunched: (state, action) => state
      .set('isLoading', true),
    getContentTypeEntriesSuccess: (state, action) => state
      .set('isLoading', false)
      .set('contentTypeEntries', action.payload),
    getContentTypeEntriesFailure: (state, action) => state
      .set('isLoading', false)
      .set('contentTypeEntries', []),
  }
});

export const {
  getSearchLaunched,
  searchSubCategory,
  getSearchSuccess,
  getSearchFailure,
  setSearchSkip,
  sendContactFormLaunched,
  sendContactFormSuccess,
  sendContactFormFailure,
  openPopup,
  resetPopupStore,
  getContentTypeEntriesLaunched,
  getContentTypeEntriesSuccess,
  getContentTypeEntriesFailure
} = actions;

export default reducer;
