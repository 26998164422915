import React from 'react'
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga';

import createRootReducer from './configureStore';
import root from './sagas'

const sagaMiddleware = createSagaMiddleware();

export default function configureStore({ element }) {
  let store;
  if (process.env.NODE_ENV === "development") {
    const composeEnhancers = window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(
      createRootReducer(),
      composeEnhancers(applyMiddleware(sagaMiddleware))
    );
  } else {
    store = createStore(
      createRootReducer(),
      compose(applyMiddleware(sagaMiddleware))
    );
  }

  sagaMiddleware.run(root);

  return <Provider store={store}>{element}</Provider>;
}
